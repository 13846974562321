import React from 'react'

const Logo = ({src, alt, customStyle}) => {
    return (
        <div className="logo" style={customStyle}>
            <img src={src} alt={alt} />
        </div>
    )
}

export default Logo