import React from "react"
import jump from "jump.js"

import Logo from "../../components/Logo"
import Container from "../../components/Container"
import LogoImage from "../../images/tas_logo_300.png"
import Wallet from "../../images/refinance_logo.png"

import "./index.scss"

const moveTo = () => jump(".order", { duration: 2000 })

const Main = ({ isThanks = false }) => {
  return (
    <div className="section main">
      <Container>
        <Logo src={LogoImage} alt={"ТАСКОМБАНК"} />
        <div className="main__wrapper">
          <div className="main__wallet">
            <img src={Wallet} alt={"Перекредитуємо!"} />
          </div>
          <div className="main__header">
            <div className="main__header--large extrabold">Пере</div>
            <div className="main__header--medium extrabold">Кредитуємо!</div>
            <div className="main__header--small extrabold">
              ВЖЕ ЗАВТРА СПЛАЧУЙТЕ МЕНШЕ!
            </div>
          </div>
        </div>
        <div className="main__footer">
          <p className="light">РЕФІНАНСУЄМО ДО 3-Х КРЕДИТІВ ОДНОЧАСНО</p>
        </div>
        {!isThanks ? (
          <div className="main__btn">
            <button onClick={moveTo} className="btn regular">
              Перекредитуватись!{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Capa_1"
                enable-background="new 0 0 551.122 551.122"
                height="512"
                viewBox="0 0 551.122 551.122"
                width="512"
              >
                <path d="m275.561 551.122c-2.573 0-5.163-.572-7.535-1.749-108.955-53.048-199.139-110.3-199.139-273.816v-189.451c0-7.249 4.524-13.708 11.336-16.18l189.451-68.892c3.801-1.379 7.972-1.379 11.773 0l189.451 68.891c6.812 2.472 11.336 8.931 11.336 16.18v189.451c0 163.516-90.184 220.768-199.139 273.816-2.371 1.178-4.961 1.75-7.534 1.75zm-172.228-452.957v177.392c0 128.482 57.992 182.454 172.228 239.135 114.236-56.681 172.228-110.653 172.228-239.135v-177.392l-172.228-62.618s-172.228 62.618-172.228 62.618z" />
                <path d="m263.384 344.447-81.068-81.068 24.354-24.354 56.714 56.714 108.382-108.383 24.354 24.354z" />
              </svg>
            </button>
          </div>
        ) : (
          <></>
        )}
      </Container>
    </div>
  )
}

export default Main
